import { Text, VStack } from "@chakra-ui/react";
import BulletinBoard from "../components/BulletinBoard";

const NoticePage = () => {
  return (
    <VStack
      style={{
        width: "100%",
        height: "100vh",
        alignItems: "flex-start",
      }}
    >
      <Text fontSize="6xl" as="b" color="#364153" m={5}>
        공지사항
      </Text>
      <div
        style={{
          height: "70%",
          width: "100%",
          marginBottom: "5rem",
        }}
      >
        <BulletinBoard
          getAccessPoint="/recruit/notices"
          postAccessPoint="/recruit/notice"
          getQualifer="notices"
          enableHtml
        />
      </div>
    </VStack>
  );
};

export default NoticePage;
