import { remote } from "../../RemoteSource";

const PostAcademyHistoryUsecase = (
  body: {
    history: Array<{
      idx: number;
      content: string;
    }>;
  },
  callbacks: {
    onSuccess: (json: { status: string }) => void;
    onFailed?: (code: number, msg?: string) => void;
  }
) => {
  remote
    .post("/admin/academy/history")
    .addBody(body)
    .onSuccess((json: any) => {
      callbacks.onSuccess(json);
    })
    .onFailed((code, msg) => {
      if (callbacks.onFailed) {
        callbacks.onFailed(code, msg);
      }
    })
    .send();
};

export default PostAcademyHistoryUsecase;
