import {
  BACKEND_URL,
  COOKIE_REFRESH,
  COOKIE_TOKEN,
  CSRF_TOKEN,
} from "../const/remote";
import { cookieManager } from "./CookieManager";
import { sessionManager } from "./SessionManager";

class Request {
  url: string;
  method: string;
  headers?: Headers;
  body?: any;
  params?: { [key: string]: any };
  type = "application/json; charset=UTF-8";
  formBody?: FormData;
  authExceptionRedirect = true;

  constructor(url: string, method: string) {
    this.url = BACKEND_URL + url;
    this.method = method;
  }

  addHeader = (key: string, value: string) => {
    if (!this.headers) {
      this.headers = new Headers();
    }
    this.headers.append(key, value);
    return this;
  };

  setType = (type: string) => {
    this.type = type;
    return this;
  };

  addBody = (body: any) => {
    this.body = body;
    return this;
  };

  addFormBody = (formBody: FormData) => {
    this.formBody = formBody;
    return this;
  };

  addParams = (params: { [key: string]: any }) => {
    this.params = params;
    return this;
  };

  setAuthExceptionRedirect = (authExceptionRedirect: boolean) => {
    this.authExceptionRedirect = authExceptionRedirect;
    return this;
  };

  successCallback = (json: string) => {};
  failedCallback = (code: number, msg?: string) => {};

  onSuccess = (callback: (json: string) => void) => {
    this.successCallback = callback;
    return this;
  };

  onFailed = (callback: (code: number, msg?: string) => void) => {
    this.failedCallback = callback;
    return this;
  };

  send = () => {
    remote.send(this);
  };
}

class RemoteSource {
  defaultHeaders = () => {
    let token = cookieManager.readCookie(COOKIE_TOKEN);
    let refresh = cookieManager.readCookie(COOKIE_REFRESH);
    let csrf = cookieManager.readCookie(CSRF_TOKEN);
    return {
      ...(token && { Authorization: "Bearer " + token }), // if token is not null, add Authorization header (token
      ...(refresh && { Refresh: refresh }),
      ...(csrf && { "X-CSRFToken": csrf }),
    };
  };

  get = (url: string) => {
    return new Request(url, "GET");
  };

  post = (url: string) => {
    return new Request(url, "POST");
  };

  put = (url: string) => {
    return new Request(url, "PUT");
  };

  delete = (url: string) => {
    return new Request(url, "DELETE");
  };

  send = async (request: Request) => {
    let headers: { [key: string]: string } = {
      ...this.defaultHeaders(),
    };

    if (request.type && !request.formBody) {
      headers["Content-Type"] = request.type;
    }

    if (request.headers) {
      request.headers.forEach((value, key) => {
        headers[key] = value;
      });
    }

    const body = request.body;
    const params = request.params;
    const url = `https://backend.hyuhyfin.com${request.url}`;
    const method = request.method;

    const urlWithParams = params
      ? `${url}?${new URLSearchParams(params)}`.replace("/?", "?")
      : url;

    /* console.log(
        `Fetch Request: ${urlWithParams} ${method} ${JSON.stringify(
          headers
        )} ${JSON.stringify(body)}`
      );*/

    try {
      const response = await fetch(urlWithParams, {
        method: method,
        headers: headers,
        body: request.formBody || JSON.stringify(body), // Make sure to stringify the body if it's not FormData
      });

      /*  console.log(
          `Fetch Response: ${response.status} ${
            response.statusText
          } ${JSON.stringify(response.headers)}`
        );*/

      this.handleCommonResponse(
        request,
        response,
        request.successCallback,
        request.failedCallback
      );
    } catch (e) {
      console.error("Fetch error:", e);
      request.failedCallback(
        400,
        e instanceof Error ? e.message : "Connection Error"
      );
    }
  };

  handleCommonResponse = async (
    request: Request,
    response: Response,
    onSuccess: (json: any) => void,
    onFailed: (code: number, msg?: string) => void
  ) => {
    if (response.status === 401) {
      // 로그인 초기화 필요
      // todo()
      onFailed(response.status, await response.text());
      if (
        window.location.pathname !== "/login" &&
        request.authExceptionRedirect
      ) {
        window.history.pushState({}, "", "/login");
        cookieManager.updateCookie(COOKIE_TOKEN, "", 1);
        cookieManager.updateCookie(COOKIE_REFRESH, "", 1);
        alert("로그인이 필요합니다.");
        window.location.reload();
      }
      return;
    }

    if (response.status === 200) {
      const contentType = response.headers.get("content-type");
      if (contentType && contentType.indexOf("application/json") === -1) {
        onSuccess(await response.text());
        return;
      }
      const json = await response.json();
      if (json.accessToken) {
        cookieManager.updateCookie(COOKIE_TOKEN, json.accessToken, 1);
        sessionManager.setLoginStatus();
      }

      onSuccess(json);
    } else {
      onFailed(response.status, await response.text());
    }
  };
}

export const remote = new RemoteSource();
