import { Text, VStack } from "@chakra-ui/react";
import BulletinBoard from "../components/BulletinBoard";
const JournalPage = () => {
  return (
    <VStack
      style={{
        width: "100%",
        height: "100vh",
        alignItems: "flex-start",
      }}
    >
      <Text fontSize="6xl" as="b" color="#364153" m={5}>
        학회지
      </Text>
      <VStack
        style={{
          height: "calc(100vh - 10rem)",
          width: "100%",
          marginBottom: "5rem",
        }}
      >
        <BulletinBoard
          getAccessPoint="/reference/journal/list"
          postAccessPoint="/reference/journal"
          getQualifer="journalList"
          fileQualifer="journalPdf"
          enablePdf
        />
      </VStack>
    </VStack>
  );
};

export default JournalPage;
