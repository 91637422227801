import React, { useState } from "react";
import IconLinkedIn from "../../resources/icon_linkedin.png";
import {
  SimpleGrid,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  FormControl,
  FormLabel,
  Input,
  useDisclosure,
  IconButton,
  Stack,
  VStack,
  HStack,
  Text,
  Checkbox,
} from "@chakra-ui/react";
import { AddIcon, DeleteIcon, EditIcon } from "@chakra-ui/icons";
import { MemberData } from "../../data/Interface/MemberData";

const emptyUser = () => {
  const emptyUser: MemberData = {
    idx: undefined,
    name: "",
    desc: "",
    generation: -1,
    pictureUrl: "",
    linkedinUrl: "",
    isActing: true,
  };
  return emptyUser;
};

const UserCard = ({
  user,
  onEdit,
  onDelete,
}: {
  user: MemberData;
  onEdit: (user: MemberData) => void;
  onDelete: (user: MemberData) => void;
}) => {
  return (
    <VStack
      style={{
        width: "12rem",
        alignItems: "flex-start",
        backgroundColor: "white",
        boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
        borderRadius: "0.5rem",
        padding: "1rem",
      }}
      spacing={1}
    >
      <HStack style={{ width: "100%" }}>
        <img
          src={
            user.pictureUrl === ""
              ? "https://cdn-icons-png.flaticon.com/512/219/219969.png"
              : user.pictureUrl
          }
          alt={user.name}
          style={{
            backgroundColor: "gray",
            width: "3rem",
            height: "3rem",
            borderRadius: "10%",
          }}
        />
        <div style={{ flex: 1 }} />
        <Text as="b" fontSize="lg">
          {user.generation}기
        </Text>
      </HStack>
      <Text as="b">{user.name}</Text>
      <Text as="p" fontSize="sm">
        {user.desc}
      </Text>

      <HStack
        style={{
          width: "100%",
          justifyContent: "flex-end",
          marginTop: "3rem",
        }}
      >
        {user.linkedinUrl && (
          <IconButton
            aria-label="edit"
            colorScheme="linkedin"
            borderRadius="50%"
            style={{
              overflow: "hidden",
            }}
            icon={
              <img
                src={IconLinkedIn}
                alt=""
                style={{
                  width: "2rem",
                  height: "2rem",
                }}
              />
            }
            size={"sm"}
            onClick={() => {
              window.open(user.linkedinUrl || "");
            }}
          />
        )}

        <IconButton
          aria-label="edit"
          colorScheme="linkedin"
          borderRadius="50%"
          icon={<EditIcon />}
          size={"sm"}
          onClick={() => onEdit(user)}
        />
        <IconButton
          aria-label="delete"
          size={"sm"}
          colorScheme="red"
          borderRadius="50%"
          icon={<DeleteIcon />}
          onClick={() => onDelete(user)}
        />
      </HStack>
    </VStack>
  );
};

const UserManagement = ({
  users,
  updateUser,
  updateUserList,
  deleteUser,
  updateUserImage,
}: {
  users: MemberData[];
  updateUserImage?: (user: MemberData, file: File) => void;
  updateUser?: (user: MemberData, image?: File) => void;
  updateUserList?: (users: MemberData[]) => void;
  deleteUser?: (user: MemberData) => void;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedUser, setSelectedUser] = useState<MemberData | undefined>(undefined);
  const [imageFile, setImageFile] = useState<File | undefined>(undefined);

  const isOnEditUser = () => {
    return selectedUser && selectedUser.idx;
  };

  const saveSelectedUser = () => {
    const isEditUser = selectedUser && selectedUser.idx;

    if (!selectedUser || !selectedUser.name) {
      alert("이름을");
      return false;
    }

    const newUser: MemberData = {
      ...(selectedUser || emptyUser()),
      idx: selectedUser?.idx,
    };

    if (imageFile) {
      newUser.pictureUrl = URL.createObjectURL(imageFile);
      updateUserImage && updateUserImage(newUser, imageFile);
    }

    updateUser && updateUser(newUser, imageFile);

    updateUserList &&
      updateUserList(
        isEditUser
          ? users.map((user) => (user.idx === newUser.idx ? newUser : user))
          : [...users, newUser]
      );

    return true;
  };

  const deleteUserData = (user: MemberData) => {
    deleteUser && deleteUser(user);
  };

  const handleEdit = (user: MemberData | null) => {
    if (user) setSelectedUser(user);
    else setSelectedUser(emptyUser());
    onOpen();
  };

  return (
    <>
      <SimpleGrid
        columns={[3, null, 4]}
        spacingX={5}
        spacingY={5}
        style={{
          width: "auto",
        }}
      >
        {/* Replace this with your user data */}
        {users.map((user: MemberData) => (
          <UserCard key={user.idx} user={user} onEdit={handleEdit} onDelete={deleteUserData} />
        ))}
        <Button
          leftIcon={<AddIcon />}
          colorScheme="green"
          onClick={() => handleEdit(null)}
          height="13rem"
          width="12rem"
        >
          회원추가
        </Button>
      </SimpleGrid>

      <Modal isOpen={isOpen} onClose={onClose} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{isOnEditUser() ? "회원 수정" : "회원 추가"}</ModalHeader>
          <ModalBody pb={6}>
            {isOnEditUser() && (
              <FormControl>
                <FormLabel>사진 업로드</FormLabel>
                <Input
                  type="file"
                  accept="image/*"
                  onChange={(e) => {
                    if (e.target.files) {
                      setImageFile(e.target.files[0]);
                    }
                  }}
                />
              </FormControl>
            )}

            <FormControl mt={4}>
              <FormLabel>이름</FormLabel>
              <Input
                placeholder="이름"
                defaultValue={selectedUser ? selectedUser.name : ""}
                onChange={(e) => {
                  setSelectedUser({
                    ...(selectedUser ?? emptyUser()),
                    name: e.target.value,
                  });
                }}
              />
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>기수</FormLabel>
              <HStack>
                <Input
                  placeholder="기수"
                  defaultValue={selectedUser ? selectedUser.generation : "0"}
                  type="number"
                  onChange={(e) => {
                    setSelectedUser({
                      ...(selectedUser ?? emptyUser()),
                      generation: parseInt(e.target.value),
                    });
                  }}
                />
                <Text
                  style={{
                    width: "3rem",
                  }}
                >
                  현역
                </Text>
                <Checkbox
                  size="lg"
                  defaultChecked={selectedUser ? selectedUser.isActing : true}
                  onChange={(e) => {
                    setSelectedUser({
                      ...(selectedUser ?? emptyUser()),
                      isActing: e.target.checked,
                    });
                  }}
                />
              </HStack>
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>학과/학번/직위</FormLabel>
              <Input
                placeholder="학과/학번/직위"
                defaultValue={selectedUser ? selectedUser.desc : ""}
                onChange={(e) => {
                  setSelectedUser({
                    ...(selectedUser ?? emptyUser()),
                    desc: e.target.value,
                  });
                }}
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>LinkedIn</FormLabel>
              <Input
                placeholder="LinkedIn"
                type="url"
                defaultValue={selectedUser ? selectedUser.linkedinUrl : ""}
                onChange={(e) => {
                  setSelectedUser({
                    ...(selectedUser ?? emptyUser()),
                    linkedinUrl: e.target.value,
                  });
                }}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={() => {
                if (saveSelectedUser()) onClose();
              }}
            >
              저장
            </Button>
            <Button onClick={onClose}>취소</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default UserManagement;
