import "./App.css";
import { ChakraProvider, HStack } from "@chakra-ui/react";
import MainPage from "./ui/pages/main";
import AppBar from "./ui/components/AppBar";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import AppStructure from "./ui/const/AppStructure";
import { sessionManager } from "./data/SessionManager";
import LoginPage from "./ui/pages/login";
import { observer } from "mobx-react";
import TestPage from "./ui/pages/test";

const App = observer(() => {
  return (
    <ChakraProvider>
      <Router>
        {sessionManager.isLogin ? (
          <HStack>
            <AppBar />
            <Routes>
              <Route path="/" element={<MainPage />} />
              {AppStructure.map((item, index) => {
                return item.subPages.map((subPage, subPageIndex) => {
                  return (
                    subPage.component && (
                      <Route
                        path={subPage.routingPath}
                        element={<subPage.component />}
                      />
                    )
                  );
                });
              })}
              <Route path="/test" element={<TestPage />} />
            </Routes>
          </HStack>
        ) : (
          <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route path="*" element={<Navigate to="/login" />} />
          </Routes>
        )}
      </Router>
    </ChakraProvider>
  );
});

export default App;
