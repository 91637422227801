import { useEffect, useState } from "react";
import PostAcademyMainUsecase from "../../data/usecase/Academy/PostAcademyMainUsecase";
import GetAcademyMainUsecase from "../../data/usecase/Academy/GetAcademyMainUsecase";
import { Input, Text, VStack } from "@chakra-ui/react";

const MainEditPage = () => {
  const [payload, setPayload] = useState("");

  const [recruit, setRecruit] = useState<{
    mainTitle: string;
    recruitKr: string;
    recruitEng: string;
    contactLabel: string;
    contactPhone: string;
    brochurePdfUrl: string;
  } | null>(null);

  const updateForm = (form: any) => {
    setRecruit(form);
    if (payload !== "") return;

    setPayload("loading");
    PostAcademyMainUsecase(form, {
      onSuccess: () => {
        setTimeout(() => {
          setPayload("");
        }, 1000);
      },
      onFailed: () => {
        setPayload("");
      },
    });
  };

  useEffect(() => {
    GetAcademyMainUsecase({
      onSuccess: (data) => {
        setRecruit(data);
      },
    });
  }, []);

  return (
    <VStack
      style={{
        height: "100vh",
        width: "98%",
        overflowY: "scroll",
        alignItems: "flex-start",
        marginLeft: "1rem",
        paddingRight: "1rem",
        paddingBottom: "5rem",
      }}
    >
      <Text fontSize="3xl" as="b">
        메인 타이틀
      </Text>
      <Input
        fontSize="xl"
        padding="1rem"
        placeholder="메인타이틀 텍스트를 입력해주세요."
        value={recruit?.mainTitle}
        onChange={(e) => updateForm({ ...recruit, mainTitle: e.target.value })}
      />
      <Text fontSize="3xl" as="b">
        영문 Recruit 메세지
      </Text>
      <Input
        fontSize="xl"
        padding="1rem"
        placeholder="영문 Recruit 메세지를 입력해주세요."
        value={recruit?.recruitEng}
        onChange={(e) => updateForm({ ...recruit, recruitEng: e.target.value })}
      />

      <Text fontSize="3xl" as="b">
        한글 Recruit 메세지
      </Text>
      <Input
        fontSize="xl"
        padding="1rem"
        placeholder="지원서 링크를 입력해주세요."
        value={recruit?.recruitKr}
        onChange={(e) => updateForm({ ...recruit, recruitKr: e.target.value })}
      />

      <Text fontSize="3xl" as="b">
        Brochure PDF링크
      </Text>
      <Input
        fontSize="xl"
        padding="1rem"
        placeholder="지원서 링크를 입력해주세요."
        value={recruit?.brochurePdfUrl}
        onChange={(e) =>
          updateForm({ ...recruit, brochurePdfUrl: e.target.value })
        }
      />

      <Text fontSize="3xl" as="b">
        문의 담당자
      </Text>
      <Input
        fontSize="xl"
        padding="1rem"
        placeholder="문의 담당자 라벨을 입력해주세요."
        value={recruit?.contactLabel}
        onChange={(e) =>
          updateForm({ ...recruit, contactLabel: e.target.value })
        }
      />
      <Text fontSize="3xl" as="b">
        담당자 연락처
      </Text>
      <Input
        fontSize="xl"
        padding="1rem"
        placeholder="연락처를 입력해주세요."
        value={recruit?.contactPhone}
        onChange={(e) =>
          updateForm({ ...recruit, contactPhone: e.target.value })
        }
      />
    </VStack>
  );
};

export default MainEditPage;
