import { remote } from "../../RemoteSource";

const PostAdminRecruitPeriodUsecase = (
  body: {
    period: string;
  },
  callbacks: {
    onSuccess: (json: { status: string }) => void;
    onFailed?: (code: number, msg?: string) => void;
  }
) => {
  remote
    .post("/admin/recruit/period")
    .addBody(body)
    .onSuccess((json: any) => {
      callbacks.onSuccess(json);
    })
    .onFailed((code, msg) => {
      if (callbacks.onFailed) {
        callbacks.onFailed(code, msg);
      }
    })
    .send();
};
export default PostAdminRecruitPeriodUsecase;
