import { remote } from "../../RemoteSource";

const DeleteMemberUsecase = (
  idx: number,
  callbacks: {
    onSuccess: (json: { status: string }) => void;
    onFailed?: (code: number, msg?: string) => void;
  }
) => {
  remote
    .delete(`/admin/member/${idx}`)
    .onSuccess((json: any) => {
      callbacks.onSuccess(json);
    })
    .onFailed((code, msg) => {
      if (callbacks.onFailed) {
        callbacks.onFailed(code, msg);
      }
    })
    .send();
};

export default DeleteMemberUsecase;
