import { remote } from "../../RemoteSource";

const GetRecruitMentUsecase = (callbacks: {
  onSuccess: (json: { status: string; ment: string }) => void;
  onFailed?: (code: number, msg?: string) => void;
}) => {
  remote
    .get("/recruit/ment")
    .onSuccess((json: any) => {
      callbacks.onSuccess(json);
    })
    .onFailed((code, msg) => {
      if (callbacks.onFailed) {
        callbacks.onFailed(code, msg);
      }
    })
    .send();
};

export default GetRecruitMentUsecase;
