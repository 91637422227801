import { Container, Text, VStack } from "@chakra-ui/react";
import VerticalList from "../components/VerticalList";
import { useEffect, useState } from "react";
import GetAcademyHistoryUsecase from "../../data/usecase/Academy/GetAcademyHistoryUsecase";
import PostAcademyHistoryUsecase from "../../data/usecase/Academy/PostAcademyHistoryUsecase";

const HistoryPage = () => {
  const [history, setHistory] = useState<{ idx: number; content: string }[]>(
    []
  );

  useEffect(() => {
    GetAcademyHistoryUsecase({
      onSuccess: (data) => {
        setHistory(data.history);
      },
    });
  }, []);

  const onListChanged = (items: { idx: number; content: string }[]) => {
    PostAcademyHistoryUsecase(
      { history: items },
      {
        onSuccess: () => {},
      }
    );
  };

  return (
    <VStack
      style={{
        width: "100%",
        height: "100vh",
        alignItems: "flex-start",
      }}
    >
      <Text fontSize="6xl" as="b" color="#364153" m={5}>
        연혁
      </Text>
      <Container
        style={{
          flex: 1,
          width: "100%",
          padding: "1rem",
          overflow: "hidden",
        }}
      >
        <VerticalList initItems={history} onListChanged={onListChanged} />
      </Container>
    </VStack>
  );
};

export default HistoryPage;
