import { Text, VStack } from "@chakra-ui/react";
import BulletinBoard from "../components/BulletinBoard";

const QnAPage = () => {
  return (
    <VStack
      style={{
        width: "100%",
        height: "100vh",
        maxHeight: "100vh",
        alignItems: "flex-start",
      }}
    >
      <Text fontSize="6xl" as="b" color="#364153" m={5}>
        QnA
      </Text>
      <VStack
        style={{
          height: "calc(100vh - 10rem)",
          width: "100%",
          marginBottom: "5rem",
        }}
      >
        <BulletinBoard
          getAccessPoint="/recruit/faqs"
          postAccessPoint="/recruit/faq"
          getQualifer="faqs"
          enableHtml
        />
      </VStack>
    </VStack>
  );
};

export default QnAPage;
