import {
  Container,
  HStack,
  IconButton,
  Input,
  Text,
  VStack,
} from "@chakra-ui/react";
import HtmlEditor from "../components/HtmlEditor";
import { useEffect, useState } from "react";
import GetRecruitMentUsecase from "../../data/usecase/Recruit/GetRecruitMentUsecase";
import GetRecruitTargetUsecase from "../../data/usecase/Recruit/GetRecruitTargetUsecase";
import GetRecruitPeriodUsecase from "../../data/usecase/Recruit/GetRecruitPeriodUsecase";
import GetRecruitApplicationFileUsecase from "../../data/usecase/Recruit/GetRecruitApplicationFileUsecase";
import GetRecruitApplicationPageUsecase from "../../data/usecase/Recruit/GetRecruitApplicationPageUsecase";
import PostAdminRecruitMentUsecase from "../../data/usecase/Recruit/PostRecruitMentUsecase";
import PostAdminRecruitTargetUsecase from "../../data/usecase/Recruit/PostRecruitTargetUsecase";
import PostAdminRecruitPeriodUsecase from "../../data/usecase/Recruit/PostRecruitPeriodUsecase";
import PostAdminApplicationFileUsecase from "../../data/usecase/Recruit/PostRecruitApplicationFileUsecase";
import PostAdminRecruitApplicationPageUsecase from "../../data/usecase/Recruit/PostRecruitApplicationPageUsecase";
import { upload } from "@testing-library/user-event/dist/upload";
import { ChevronUpIcon, DownloadIcon } from "@chakra-ui/icons";

const RecruitPage = () => {
  const [recruitMent, setRecruitMent] = useState("");
  const [recruitTarget, setRecruitTarget] = useState("");
  const [recruitPeriod, setRecruitPeriod] = useState("");
  const [recruitApplicationFileUrl, setRecruitApplicationFileUrl] =
    useState("");
  const [recruitApplicationPageUrl, setRecruitApplicationPageUrl] =
    useState("");

  useEffect(() => {
    GetRecruitMentUsecase({
      onSuccess: (data) => {
        setRecruitMent(data.ment);
      },
    });

    GetRecruitTargetUsecase({
      onSuccess: (data) => {
        setRecruitTarget(data.target);
      },
    });

    GetRecruitPeriodUsecase({
      onSuccess: (data) => {
        setRecruitPeriod(data.period);
      },
    });

    GetRecruitApplicationFileUsecase({
      onSuccess: (data) => {
        setRecruitApplicationFileUrl(data.applicationFileUrl);
      },
    });

    GetRecruitApplicationPageUsecase({
      onSuccess: (data) => {
        setRecruitApplicationPageUrl(data.applicationPageUrl);
      },
    });
  }, []);

  const updateRecruitMent = (html: string) => {
    if (html === "<p><br></p>") html = "";
    if (html.length < 1) return;
    PostAdminRecruitMentUsecase(
      { ment: html },
      {
        onSuccess: () => {
          setRecruitMent(html);
        },
      }
    );
  };

  const updateRecruitTarget = (html: string) => {
    if (html === "<p><br></p>") html = "";
    if (html.length < 1) return;
    PostAdminRecruitTargetUsecase(
      { target: html },
      {
        onSuccess: () => {
          setRecruitTarget(html);
        },
      }
    );
  };

  const updateRecruitPeriod = (html: string) => {
    if (html === "<p><br></p>") html = "";
    if (html.length < 1) return;
    PostAdminRecruitPeriodUsecase(
      { period: html },
      {
        onSuccess: () => {
          setRecruitPeriod(html);
        },
      }
    );
  };

  const updateRecruitApplicationFileUrl = (e: any) => {
    PostAdminApplicationFileUsecase(
      { file: e.target.files[0] },
      {
        onSuccess: (response) => {
          setRecruitApplicationFileUrl(response.applicationFileUrl);
        },
        onFailed: () => {
          alert("파일 업로드에 실패했습니다.");
        },
      }
    );
  };

  const updateRecruitApplicationPageUrl = (e: any) => {
    const url = e.target.value;
    if (url.length < 1) return;
    setRecruitApplicationPageUrl(url);
  };

  const postRecruitApplicationPageUrl = () => {
    PostAdminRecruitApplicationPageUsecase(
      { applicationPageUrl: recruitApplicationPageUrl },
      {
        onSuccess: () => {},
      }
    );
  };

  const downloadApplicationFile = () => {
    window.open(recruitApplicationFileUrl);
  };

  return (
    <VStack
      style={{
        height: "100vh",
        width: "98%",
        overflowY: "scroll",
        alignItems: "flex-start",
        marginLeft: "1rem",
        paddingRight: "1rem",
        paddingBottom: "5rem",
      }}
    >
      <Text fontSize="6xl" as="b">
        모집안내
      </Text>
      <Text fontSize="3xl" as="b">
        초청의 말
      </Text>
      <div
        style={{
          height: "50rem",
          width: "100%",
        }}
      >
        <HtmlEditor
          initialHtmlContent={recruitMent}
          onTextChange={updateRecruitMent}
        />
      </div>
      <Text fontSize="3xl" as="b">
        모집 대상
      </Text>
      <div
        style={{
          height: "50rem",
          width: "100%",
        }}
      >
        <HtmlEditor
          initialHtmlContent={recruitTarget}
          onTextChange={updateRecruitTarget}
        />
      </div>
      <Text fontSize="3xl" as="b">
        모집 일정
      </Text>
      <div
        style={{
          height: "50rem",
          width: "100%",
        }}
      >
        <HtmlEditor
          initialHtmlContent={recruitPeriod}
          onTextChange={updateRecruitPeriod}
        />
      </div>

      <Text fontSize="3xl" as="b">
        지원서 작성 링크
      </Text>
      <HStack
        style={{
          width: "100%",
        }}
      >
        <Input
          type="file"
          onChange={(e) => {
            updateRecruitApplicationFileUrl(e);
          }}
          fontSize="sm"
          padding="2rem 1rem"
          style={{
            height: "3rem",
            width: "100%",
          }}
          variant="ghost"
          defaultValue={recruitApplicationFileUrl}
          placeholder="지원서를 업로드해주세요."
        />
        {recruitApplicationFileUrl.length > 0 && (
          <IconButton
            aria-label="Search database"
            icon={<DownloadIcon />}
            onClick={downloadApplicationFile}
          />
        )}
      </HStack>

      <Text fontSize="3xl" as="b">
        서류 지원 링크
      </Text>
      <HStack
        style={{
          width: "100%",
        }}
      >
        <Input
          fontSize="xl"
          padding="1rem"
          placeholder="서류지원 링크를 입력해주세요."
          onChange={updateRecruitApplicationPageUrl}
          defaultValue={recruitApplicationPageUrl}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              postRecruitApplicationPageUrl();
            }
          }}
        />
        <IconButton
          aria-label="Search database"
          icon={<ChevronUpIcon />}
          onClick={postRecruitApplicationPageUrl}
        />
      </HStack>
    </VStack>
  );
};

export default RecruitPage;
