import { remote } from "../../RemoteSource";

const PostAcademyCurriculumUsecase = (
  body: {
    curriculum: string;
  },
  callbacks: {
    onSuccess: (json: { status: string }) => void;
    onFailed?: (code: number, msg?: string) => void;
  }
) => {
  remote
    .post("/admin/academy/curriculum")
    .addBody(body)
    .onSuccess((json: any) => {
      callbacks.onSuccess(json);
    })
    .onFailed((code, msg) => {
      if (callbacks.onFailed) {
        callbacks.onFailed(code, msg);
      }
    })
    .send();
};
export default PostAcademyCurriculumUsecase;
