import { useState } from "react";
import { PostData } from "../../data/Interface/PostData";
import { HStack, IconButton, Text, VStack } from "@chakra-ui/react";
import { DeleteIcon, DownloadIcon, EditIcon } from "@chakra-ui/icons";

const PostView = ({
  post,
  fileQualifer,
  onEdit,
  onDelete,
}: {
  post: PostData;
  fileQualifer?: string;
  onEdit: (post: PostData) => void;
  onDelete: (post: PostData) => void;
}) => {
  const [year, month, day] = post.createdAt
    ? post.createdAt.split("T")[0].split("-")
    : ["", "", ""];
  const [hover, setHover] = useState(false);

  const fileDownloadUrl = fileQualifer
    ? (post as any)[fileQualifer]
    : undefined;

  const thumbnailUrl = (post as any)["pictureUrl"];

  return (
    <HStack
      onPointerLeave={(e) => {
        setHover(false);
      }}
      onPointerEnter={(e) => {
        setHover(true);
      }}
      style={{
        width: "100%",
        height: "10rem",
        alignItems: "flex-end",
        backgroundColor: hover ? "#F5F5F5" : "white",
        borderRadius: "0.5rem",
        padding: "1rem",
      }}
    >
      {thumbnailUrl && (
        <img
          src={thumbnailUrl}
          style={{
            width: "8rem",
            height: "8rem",
            objectFit: "cover",
          }}
          alt=""
        />
      )}
      <VStack
        spacing="-0.5rem"
        style={{
          height: "100%",
          alignItems: "flex-start",
        }}
      >
        <Text fontSize="md">
          {month}.{day}
        </Text>
        <Text fontSize="sm" textColor="gray">
          {year}
        </Text>
      </VStack>
      <VStack
        style={{
          flex: 1,
          alignItems: "flex-start",
          height: "100%",
          justifyContent: "flex-start",
        }}
      >
        <Text
          fontSize="2xl"
          as="b"
          style={{
            marginLeft: "1rem",
          }}
        >
          {post.title}
        </Text>
        <Text
          fontSize="sm"
          fontWeight="300"
          noOfLines={3}
          style={{
            marginLeft: "1.2rem",
            paddingBottom: "1rem",
            flex: 1,
          }}
        >
          {
            //post.content, html 태그 제거
            post.content.replace(/(<([^>]+)>)/gi, "")
          }
        </Text>
      </VStack>
      {fileDownloadUrl && (
        <IconButton
          aria-label="Download"
          colorScheme="green"
          size="sm"
          icon={<DownloadIcon />}
          onClick={() => window.open(fileDownloadUrl)}
        />
      )}
      <IconButton
        aria-label="Edit"
        colorScheme="linkedin"
        size="sm"
        icon={<EditIcon />}
        onClick={() => onEdit(post)}
      />
      <IconButton
        aria-label="Delete"
        colorScheme="red"
        size="sm"
        icon={<DeleteIcon />}
        onClick={() => onDelete(post)}
      />
    </HStack>
  );
};

export default PostView;
