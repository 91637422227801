import { useEffect, useState } from "react";
import {
  VStack,
  useDisclosure,
  IconButton,
  HStack,
  Input,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import { PostData } from "../../data/Interface/PostData";
import {
  DeleteAdminPostUsecase,
  GetPostListUsecase,
  PostAdminPostUsecase,
  PostFormAdminPostUsecase,
  PostFormImageDetailUseCase,
} from "../../data/usecase/Recruit/PostsUsecase";
import PostView from "./BulletinBoardPost";
import BulletinBoardEditModal from "./BulletinBoardEditModal";

const BulletinBoard = ({
  getAccessPoint,
  postAccessPoint,
  getQualifer,
  fileQualifer,
  enablePdf,
  enableImage,
  enableImageMulti,
  activityType,
  imageIdxQualifier,
  enableHtml,
}: {
  getAccessPoint?: string;
  postAccessPoint?: string;
  fileQualifer?: string;
  getQualifer?: string;
  enablePdf?: boolean;
  enableImage?: boolean;
  enableImageMulti?: boolean;
  activityType?: string;
  imageIdxQualifier?: string;
  enableHtml?: boolean;
}) => {
  const [posts, setPosts] = useState<PostData[]>([]); // replace with your actual posts data
  const { isOpen, onOpen, onClose } = useDisclosure();
  const newPostValue = () => {
    return {
      title: "",
      content: "",
    };
  };
  const [currentPostData, setCurrentPostData] = useState<PostData>(
    newPostValue()
  );
  const setCurrentPost = (post: PostData) => {
    setCurrentPostData(post);
  };

  const [titleFilter, setTitleFilter] = useState("");

  const openModal = (post: PostData) => {
    setCurrentPost(post);

    onOpen();
  };

  const fetchPosts = () => {
    if (!getAccessPoint || !getQualifer) return;
    GetPostListUsecase(
      getAccessPoint!,
      {
        onSuccess: (data) => {
          //get post from data, with postType
          // ex : data.recruit if postType = "recruit"
          setPosts(data[getQualifer!]);
        },

        onFailed(code, msg) {
          alert(msg);
        },
      },
      activityType
    );
  };

  const uploadPost = (
    post: PostData,
    pdfFileChosen?: File,
    imageFilesChosen?: File,
    imageFilesChosenList?: (File | string)[]
  ) => {
    if (enableImage || enablePdf) {
      PostFormAdminPostUsecase(
        postAccessPoint!,
        {
          ...post,
          type: activityType,
        },
        fileQualifer!,
        pdfFileChosen,
        imageFilesChosen,
        {
          onSuccess: (data) => {
            onSuccessUploadPost(post, imageFilesChosenList);
          },
          onFailed(code, msg) {
            alert(msg);
          },
        }
      );

      return;
    }
    PostAdminPostUsecase(postAccessPoint!, post, {
      onSuccess: (data) => {
        onSuccessUploadPost(post);
      },
      onFailed(code, msg) {
        alert(msg);
      },
    });
  };

  const onSuccessUploadPost = (
    updatedPost: PostData,
    imageFilesChosenList?: (File | string)[]
  ) => {
    if (imageFilesChosenList && updatedPost.idx && enableImageMulti) {
      let urls = [];
      let files = [];
      for (let i = 0; i < imageFilesChosenList.length; i++) {
        if (typeof imageFilesChosenList[i] === "string") {
          urls.push(imageFilesChosenList[i] as string);
        } else {
          urls.push("PICTURE");
          files.push(imageFilesChosenList[i] as File);
        }
      }
      PostFormImageDetailUseCase(
        updatedPost.idx!,
        postAccessPoint!,
        urls,
        files,
        {
          onSuccess: (data) => {
            fetchPosts();
          },
        }
      );
    } else {
      fetchPosts();
    }
    onClose();
  };

  const deletePost = (id: number | undefined) => {
    if (!id) return;

    DeleteAdminPostUsecase(postAccessPoint!, id, {
      onSuccess: (data) => {
        setPosts(posts.filter((post) => post.idx !== id));
      },
      onFailed(code, msg) {
        alert(msg);
      },
    });
  };

  useEffect(() => {
    fetchPosts();
  }, [activityType]);

  return (
    <VStack
      spacing={4}
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <VStack
        style={{
          width: "100%",
          flexGrow: 1,
          overflowY: "auto",
        }}
      >
        {posts
          .filter((post) => post.title.split(titleFilter).length > 1)
          .map((post, index) => (
            <PostView
              key={index}
              post={post}
              onEdit={(post) => openModal(post)}
              onDelete={(post) => deletePost(post.idx)}
              fileQualifer={`${fileQualifer}Url`}
            />
          ))}
      </VStack>
      <Input
        style={{
          width: "20rem",
        }}
        placeholder="Search"
        value={titleFilter}
        onChange={(e) => setTitleFilter(e.target.value)}
        size="lg"
      />
      <HStack
        style={{
          width: "100%",
          justifyContent: "flex-end",
          marginRight: "3rem",
          marginTop: "-3rem",
        }}
      >
        <IconButton
          icon={<AddIcon />}
          aria-label="add"
          size="lg"
          colorScheme="whatsapp"
          borderRadius="50%"
          onClick={() => openModal(newPostValue())}
        />
      </HStack>

      <BulletinBoardEditModal
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        currentPostData={currentPostData}
        enablePdf={enablePdf}
        enableImage={enableImage}
        enableImageMulti={enableImageMulti}
        fileQualifer={fileQualifer}
        postAccessPoint={postAccessPoint}
        imageIdxQualifier={imageIdxQualifier}
        uploadPost={uploadPost}
        enableHtml={enableHtml}
      />
    </VStack>
  );
};

export default BulletinBoard;
