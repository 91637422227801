import { remote } from "../../RemoteSource";

const ProfileUsecase = (callback: { onSuccess: (json: any) => void }) => {
  remote
    .get("/admin/user/profile")
    .onSuccess((json) => {
      callback.onSuccess(json);
    })
    .onFailed((code, msg) => {})
    .send();
};

export default ProfileUsecase;
