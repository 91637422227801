import { remote } from "../../RemoteSource";

const GetAcademyMainUsecase = (callbacks: {
  onSuccess: (json: {
    status: string;
    mainTitle: string;
    recruitKr: string;
    recruitEng: string;
    contactLabel: string;
    contactPhone: string;
    brochurePdfUrl: string;
  }) => void;
  onFailed?: (code: number, msg?: string) => void;
}) => {
  remote
    .get("/academy/main")
    .onSuccess((json: any) => {
      callbacks.onSuccess(json);
    })
    .onFailed((code, msg) => {
      if (callbacks.onFailed) {
        callbacks.onFailed(code, msg);
      }
    })
    .send();
};

export default GetAcademyMainUsecase;
