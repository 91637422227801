import { remote } from "../../RemoteSource";

const GetAcademyHistoryUsecase = (callbacks: {
  onSuccess: (json: {
    status: string;
    history: { idx: number; content: string }[];
  }) => void;
  onFailed?: (code: number, msg?: string) => void;
}) => {
  remote
    .get("/academy/history")
    .onSuccess((json: any) => {
      callbacks.onSuccess(json);
    })
    .onFailed((code, msg) => {
      if (callbacks.onFailed) {
        callbacks.onFailed(code, msg);
      }
    })
    .send();
};

export default GetAcademyHistoryUsecase;
