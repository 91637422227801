import { Button, Text, VStack } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import AppStructure from "../const/AppStructure";
import { useNavigate } from "react-router-dom";
import { cookieManager } from "../../data/CookieManager";
import { COOKIE_TOKEN } from "../../const/remote";
import { sessionManager } from "../../data/SessionManager";

const AppBar = observer(() => {
  const navigate = useNavigate();

  const adminLogout = () => {
    sessionManager.logout();
    navigate("/login");
  };
  return (
    <VStack
      style={{
        width: "10rem",
        backgroundColor: "#364153",
        height: "100vh",
        boxShadow: "0 0 0.5rem 0.25rem rgba(0, 0, 0, 0.25)",
      }}
    >
      {AppStructure.map((item, index) => {
        return (
          <VStack
            key={index}
            style={{
              width: "100%",
            }}
          >
            <Button
              style={{
                width: "100%",
                height: "3rem",
              }}
              borderRadius={0}
              textColor="white"
              variant="ghost"
              onClick={() => {
                navigate(item.subPages[0].routingPath);
              }}
            >
              <Text>{item.pageName}</Text>
            </Button>
            <VStack
              style={{
                width: "100%",

                alignItems: "flex-end",
              }}
            >
              {item.subPages.map((subPage, subPageIndex) => {
                return window.location.pathname.split("/")[1] ===
                  subPage.routingPath ? (
                  <Text
                    key={subPageIndex}
                    fontSize="md"
                    as="b"
                    style={{
                      width: "100%",
                      paddingLeft: "1rem",
                      paddingRight: "1rem",
                      textAlign: "right",
                      color: "#292C33",
                      backgroundColor: "#ffffff",
                      height: "2rem",
                      lineHeight: "2rem",
                    }}
                  >
                    {subPage.pageName}
                  </Text>
                ) : (
                  <Button
                    key={subPageIndex}
                    variant="ghost"
                    size="sm"
                    borderRadius={0}
                    ringColor="white"
                    textColor="white"
                    justifyContent={"flex-end"}
                    style={{
                      width: "100%",
                      paddingLeft: "1rem",
                      paddingRight: "1rem",
                    }}
                    onClick={() => {
                      navigate(subPage.routingPath);
                    }}
                  >
                    {subPage.pageName}
                  </Button>
                );
              })}
            </VStack>
          </VStack>
        );
      })}
      <Button
        variant="ghost"
        size="sm"
        borderRadius={0}
        ringColor="white"
        textColor="white"
        justifyContent={"flex-end"}
        style={{
          width: "100%",
          paddingRight: "3rem",
        }}
        onClick={() => {
          adminLogout();
        }}
      >
        로그아웃
      </Button>
    </VStack>
  );
});

export default AppBar;
