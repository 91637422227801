import React, { useEffect, useState } from "react";
import {
  VStack,
  Input,
  Button,
  Text,
  HStack,
  IconButton,
} from "@chakra-ui/react";
import {
  AddIcon,
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  DeleteIcon,
  EditIcon,
} from "@chakra-ui/icons";

const VerticalList = ({
  onListChanged,
  initItems,
}: {
  onListChanged?: (items: { idx: number; content: string }[]) => void;
  initItems?: { idx: number; content: string }[];
}) => {
  const [items, setItems] = useState<{ idx: number; content: string }[]>([]);
  const [newItem, setNewItem] = useState("");
  const [editingItem, setEditingItem] = useState("");

  useEffect(() => {
    if (initItems) {
      setItems(initItems);
    }
  }, [initItems]);

  const handleAddItem = () => {
    if (newItem.trim().length < 2) {
      setNewItem("");
      return;
    }
    const newItemObj = {
      idx: Math.random(), // Simple unique ID generator
      content: newItem,
    };

    handleSetItems(
      [...items, newItemObj].map((item, index) => ({ ...item, idx: index }))
    );
    setNewItem("");
  };

  const handleSetItems = (items: { idx: number; content: string }[]) => {
    setItems(items);
    if (onListChanged)
      onListChanged(items.map((item, index) => ({ ...item, idx: index + 1 })));
  };

  const moveItem = (currentIndex: number, direction: number) => {
    if (
      currentIndex + direction < 0 ||
      currentIndex + direction >= items.length
    )
      return;
    const newItems = [...items];
    const item = newItems.splice(currentIndex, 1)[0];
    newItems.splice(currentIndex + direction, 0, item);
    handleSetItems(newItems);
  };

  const DeleteItem = (currentIndex: number) => {
    const newItems = [...items];
    newItems.splice(currentIndex, 1);
    handleSetItems(newItems);
  };

  const [editableId, setEditableId] = useState<number | null>(null);

  const handleDoubleClick = (id: number) => {
    setEditableId(id);
    setEditingItem(items.find((item) => item.idx === id)?.content || "");
  };

  const handleEditItem = (id: number) => {
    const value = editingItem.trim();
    const newItems = [...items].map((item) =>
      item.idx === id ? { ...item, content: value } : item
    );
    handleSetItems(newItems);
    setEditableId(null);
  };

  return (
    <VStack style={{ height: "100%", overflowY: "hidden" }}>
      <VStack
        spacing={0}
        style={{
          width: "100%",
          overflowY: "scroll",
          flex: 1,
          borderRadius: "0.5rem",
        }}
      >
        {items.map((item, index) => (
          <HStack
            key={item.idx}
            width="100%"
            style={{
              backgroundColor: index % 2 === 0 ? "#2e4e3f" : "#ffffff",
              height: "3rem",
              padding: "0.5rem",
            }}
            onBlur={() => {
              if (editableId) {
                handleEditItem(editableId);
              }
            }}
          >
            {editableId === item.idx ? (
              <>
                <Input
                  id="editItem"
                  autoFocus
                  defaultValue={item.content}
                  onChange={(e) => setEditingItem(e.target.value)}
                  textColor={index % 2 ? "black" : "white"}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      handleEditItem(item.idx);
                    }
                  }}
                />
                <IconButton
                  size="sm"
                  aria-label="Edit Done"
                  icon={<CheckIcon />}
                  colorScheme="twitter"
                  onClick={() => handleEditItem(item.idx)}
                />
              </>
            ) : (
              <Text
                flex={1}
                onDoubleClick={() => handleDoubleClick(item.idx)}
                color={index % 2 ? "black" : "white"}
              >
                {item.content}
              </Text>
            )}
            {editableId !== item.idx && (
              <IconButton
                size="sm"
                aria-label="Edit"
                icon={<EditIcon />}
                colorScheme="twitter"
                onClick={() => handleDoubleClick(item.idx)}
              />
            )}
            <IconButton
              size="sm"
              aria-label="Move down"
              colorScheme="blue"
              icon={<ChevronDownIcon />}
              onClick={() => moveItem(index, 1)}
              isDisabled={index === items.length - 1}
            />
            <IconButton
              size="sm"
              aria-label="Move up"
              colorScheme="green"
              icon={<ChevronUpIcon />}
              onClick={() => moveItem(index, -1)}
              isDisabled={index === 0}
            />

            <IconButton
              size="sm"
              aria-label="Delete"
              icon={<DeleteIcon />}
              colorScheme="red"
              onClick={() => DeleteItem(index)}
            />
          </HStack>
        ))}
      </VStack>
      <HStack
        style={{
          marginTop: "0.5rem",
          width: "100%",
        }}
      >
        <Input
          value={newItem}
          onChange={(e) => setNewItem(e.target.value)}
          placeholder="새로운 항목을 입력하고 Enter를 누르거나 +버튼을 누르세요"
          onKeyDown={(e) => {
            if (e.key === "Enter") handleAddItem();
          }}
        />
        <IconButton
          onClick={handleAddItem}
          aria-label="Add new item"
          icon={<AddIcon />}
        />
      </HStack>
    </VStack>
  );
};

export default VerticalList;
