import { Text, VStack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { MemberData } from "../../data/Interface/MemberData";
import UserManagement from "../components/UserManagement";
import GetAdminMemberListUsecase from "../../data/usecase/Member/GetMemberListUsecase";
import PostAdminMemberUsecase from "../../data/usecase/Member/PostMemberUsecase";
import PostAdminMemberUpdateUsecase from "../../data/usecase/Member/MemberUpdateUsecase";
import DeleteAdminUserUsecase from "../../data/usecase/User/DeleteUserUsecase";
import DeleteMemberUsecase from "../../data/usecase/Member/DeleteMemberUsecase";

const AlumniPage = () => {
  const [users, setUsers] = useState<MemberData[]>([]);

  useEffect(() => {
    refreshUsers();
  }, []);

  const refreshUsers = () => {
    GetAdminMemberListUsecase({
      onSuccess: (data) => {
        setUsers(data.members);
      },
    });
  };

  const updateUser = (user: MemberData, image?: File) => {
    if (user.idx) {
      PostAdminMemberUpdateUsecase(
        user.idx,
        {
          ...user,
          picture: image,
        },
        {
          onSuccess: () => {
            refreshUsers();
          },
        }
      );
    } else {
      PostAdminMemberUsecase(user, {
        onSuccess: () => {
          refreshUsers();
        },
      });
    }
  };

  const deleteUser = (user: MemberData) => {
    if (user.idx) {
      DeleteMemberUsecase(user.idx, {
        onSuccess: () => {
          refreshUsers();
        },
        onFailed: (code, msg) => {
          if (code === 401) {
            alert("권한이 없습니다.");
          }
        },
      });
    }
  };

  return (
    <VStack
      style={{
        width: "100%",
        height: "100vh",
        alignItems: "flex-start",
      }}
    >
      <Text fontSize="6xl" as="b" color="#364153" m={5}>
        회원 목록
      </Text>
      <div
        style={{
          flex: 1,
          overflowY: "scroll",
          paddingBottom: "5rem",
          padding: "1rem",
        }}
      >
        <UserManagement
          users={users}
          updateUserList={(users) => setUsers(users)}
          updateUser={updateUser}
          deleteUser={deleteUser}
        />
      </div>
    </VStack>
  );
};
export default AlumniPage;
