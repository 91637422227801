import { Container, Text, VStack } from "@chakra-ui/react";
import HtmlEditor from "../components/HtmlEditor";
import PostAcademyGreetingsUsecase from "../../data/usecase/Academy/PostAcademyGreetingsUsecase";
import { useEffect, useState } from "react";
import GetAcademyGreetingsUsecase from "../../data/usecase/Academy/GetAcademyGreetingsUsecase";

const GreetingPage = () => {
  const [greetings, setGreetings] = useState("");

  const updateHTML = (html: string) => {
    PostAcademyGreetingsUsecase(
      { greetings: html },
      {
        onSuccess: () => {},
      }
    );
  };

  useEffect(() => {
    GetAcademyGreetingsUsecase({
      onSuccess: (data) => {
        setGreetings(data.greetings);
      },
    });
  }, []);

  return (
    <VStack
      style={{
        width: "100%",
        alignItems: "flex-start",
        paddingLeft: "1rem",
        height: "100vh",
      }}
    >
      <Text fontSize="6xl" as="b">
        인사말
      </Text>
      <div
        style={{
          width: "95%",
          height: "70%",
        }}
      >
        <HtmlEditor initialHtmlContent={greetings} onTextChange={updateHTML} />
      </div>
    </VStack>
  );
};

export default GreetingPage;
