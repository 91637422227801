import { useEffect, useRef, useState } from "react";
import SunEditor from "suneditor-react";
import SunEditorCore from "suneditor/src/lib/core";
import "suneditor/dist/css/suneditor.min.css";
const HtmlEditor = ({
  initialHtmlContent,
  onTextChange,
}: {
  initialHtmlContent?: string;
  onTextChange?: (text: string) => void;
}) => {
  const editor = useRef<SunEditorCore>();

  const getSunEditorInstance = (sunEditor: SunEditorCore) => {
    editor.current = sunEditor;
  };
  const editorContainerRef = useRef<HTMLDivElement>(null);
  const [editorHeight, setEditorHeight] = useState<string>("300px"); // Default height

  useEffect(() => {
    // Function to update editor height
    const updateEditorHeight = () => {
      if (editorContainerRef.current) {
        const height =
          editorContainerRef.current.getBoundingClientRect().height;
        setEditorHeight(`${height}px`);
      }
    };

    // Call the function to update height
    updateEditorHeight();

    // Add event listener to update height on window resize
    window.addEventListener("resize", updateEditorHeight);

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", updateEditorHeight);
  }, []); // Empty dependency array ensures this runs once after mount

  return (
    <div ref={editorContainerRef} style={{ width: "100%", height: "80%" }}>
      {" "}
      {/* This should be the parent container with a calculable height */}
      <SunEditor
        height={editorHeight}
        setContents={initialHtmlContent}
        getSunEditorInstance={getSunEditorInstance}
        setOptions={{
          buttonList: [
            ["undo", "redo"],
            ["font", "fontSize", "formatBlock"],
            ["paragraphStyle", "blockquote"],
            [
              "bold",
              "underline",
              "italic",
              "strike",
              "subscript",
              "superscript",
            ],
            ["fontColor", "hiliteColor", "textStyle"],
            ["removeFormat"],
            // Line break
            ["outdent", "indent"],
            ["align", "horizontalRule", "list", "lineHeight"],

            //["table", "link", "image", "video", "audio" /** ,'math' */], // You must add the 'katex' library at options to use the 'math' plugin.

            /** ['imageGallery'] */ // You must add the "imageGalleryUrl".

            //["fullScreen", "showBlocks", ],
            ["codeView", "preview", "print"],
            //["save", "template"],
            /** ['dir', 'dir_ltr', 'dir_rtl'] */ // "dir": Toggle text direction, "dir_ltr": Right to Left, "dir_rtl": Left to Right
          ],
        }}
        onChange={(content) => {
          if (onTextChange) onTextChange(content);
        }}
      />
    </div>
  );
};
export default HtmlEditor;

/*
interface HtmlEditorProps {
  initialHtmlContent: string;
  onHtmlContentChange: (content: string) => void;
}

const HtmlEditorInput: React.FC<HtmlEditorProps> = ({
  initialHtmlContent,
  onHtmlContentChange,
}) => {
  const editorContainerRef = useRef<HTMLDivElement>(null);
  const { setContainer, state } = useCodeMirror({
    extensions: [
      html(),
      EditorView.updateListener.of((update) => {
        if (update.docChanged) {
          onHtmlContentChange(update.state.doc.toString());
        }
      }),
      EditorView.theme({
        "&": {
          backgroundColor: "#292C33",
        },
        ".cm-scroller": {},
      }),
    ],
    value: initialHtmlContent,
    theme: "dark",
  });

  useEffect(() => {
    if (editorContainerRef.current) {
      setContainer(editorContainerRef.current);
    }
  }, [setContainer]);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: "#292C33",
      }}
      ref={editorContainerRef}
    />
  );
};

const HtmlEditor = ({
  initialHtmlContent,
  onTextChange,
}: {
  initialHtmlContent?: string;
  onTextChange?: (text: string) => void;
}) => {
  const [htmlContent, setHtmlContent] = useState(initialHtmlContent || "");
  const [isPreview, setIsPreview] = useState(false);

  const handleHtmlContentChange = (content: string) => {
    setHtmlContent(content);
    if (onTextChange) onTextChange(content);
  };

  const togglePreview = () => {
    setIsPreview(!isPreview);
  };

  return (
    <VStack
      spacing={0}
      style={{
        width: "90%",
        padding: "0.5rem 2rem",
        margin: "5%",

        backgroundColor: "#272930",
        borderRadius: "0.5rem",
        height: "100%",
      }}
    >
      <HStack justifyContent="flex-start" width="100%">
        <Text
          textColor="white"
          style={{
            width: "10rem",
            height: "2rem",
            textAlign: "center",
            backgroundColor: isPreview ? "#272930" : "#292C33",
          }}
          onClick={togglePreview}
        >
          HTML
        </Text>
        <Text
          textColor="white"
          style={{
            width: "10rem",
            height: "2rem",
            textAlign: "center",
            backgroundColor: !isPreview ? "#272930" : "#292C33",
          }}
          onClick={togglePreview}
        >
          미리보기
        </Text>
      </HStack>

      <div
        style={{
          overflowY: "scroll",
          width: "100%",
          flex: 1,
          backgroundColor: !isPreview ? "#272930" : "#ffffff",
        }}
      >
        {isPreview ? (
          <div
            style={{
              width: "100%",
              padding: "0.5rem 2rem",
              borderRadius: "0.5rem",
              height: "auto",
            }}
            dangerouslySetInnerHTML={{ __html: htmlContent }}
          />
        ) : (
          <HtmlEditorInput
            initialHtmlContent={htmlContent}
            onHtmlContentChange={handleHtmlContentChange}
          />
        )}
      </div>
    </VStack>
  );
};

export default HtmlEditor;
*/
