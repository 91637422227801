import { PostData } from "../../Interface/PostData";
import { remote } from "../../RemoteSource";

const GetPostListUsecase = (
  accessPoint: string,
  callbacks: {
    onSuccess: (json: any) => void;
    onFailed?: (code: number, msg?: string) => void;
  },
  type: string | undefined
) => {
  const url = type ? `${accessPoint}?type=${type}` : `${accessPoint}`;
  remote
    .get(url)
    .onSuccess((json: any) => {
      callbacks.onSuccess(json);
    })
    .onFailed((code, msg) => {
      if (callbacks.onFailed) {
        callbacks.onFailed(code, msg);
      }
    })
    .send();
};

const GetPostImageDetailsUseCase = (
  idx: number,
  accessPoint: string,
  qualifier: string,
  callbacks: {
    onSuccess: (json: {
      status: string;
      pictures: { idx: number; pictureUrl: string }[];
    }) => void;
    onFailed?: (code: number, msg?: string) => void;
  }
) => {
  remote
    .get(`${accessPoint}/detail?${qualifier}=${idx}`)
    .onSuccess((json: any) => {
      callbacks.onSuccess(json);
    })
    .onFailed((code, msg) => {
      if (callbacks.onFailed) {
        callbacks.onFailed(code, msg);
      }
    })
    .send();
};

const PostAdminPostUsecase = (
  accessPoint: string,
  body: PostData,
  callbacks: {
    onSuccess: (json: { status: string }) => void;
    onFailed?: (code: number, msg?: string) => void;
  }
) => {
  const url = body.idx
    ? `/admin${accessPoint}/${body.idx}`
    : `/admin${accessPoint}`;
  remote
    .post(url)
    .addBody(body)
    .onSuccess((json: any) => {
      callbacks.onSuccess(json);
    })
    .onFailed((code, msg) => {
      if (callbacks.onFailed) {
        callbacks.onFailed(code, msg);
      }
    })
    .send();
};

const PostFormAdminPostUsecase = (
  accessPoint: string,
  body: PostData,
  fileQualifer: string,
  file: File | undefined,
  picture: File | undefined,
  callbacks: {
    onSuccess: (json: { status: string }) => void;
    onFailed?: (code: number, msg?: string) => void;
  }
) => {
  const formData = new FormData();
  formData.append("title", body.title);
  formData.append("content", body.content);
  if (body.type) formData.append("type", body.type);
  if (file) formData.append(fileQualifer, file);
  if (picture) formData.append("picture", picture);
  if (body.idx) formData.append("idx", body.idx!.toString());
  const url = body.idx
    ? `/admin${accessPoint}/${body.idx}`
    : `/admin${accessPoint}`;
  remote
    .post(url)
    .addFormBody(formData)
    .onSuccess((json: any) => {
      callbacks.onSuccess(json);
    })
    .onFailed((code, msg) => {
      if (callbacks.onFailed) {
        callbacks.onFailed(code, msg);
      }
    })
    .send();
};

const PostFormImageDetailUseCase = (
  idx: number,
  accessPoint: string,
  urls: string[],
  files: File[],
  callbacks: {
    onSuccess: (json: { status: string }) => void;
    onFailed?: (code: number, msg?: string) => void;
  }
) => {
  const formData = new FormData();
  /**
   * {
	"pictures": [<picture>, <picture>, ...]
}
   */
  urls.forEach((url) => {
    formData.append("pictureUrls", url);
  });
  files.forEach((file) => {
    formData.append("pictures", file);
  });
  const url = `/admin${accessPoint}/detail/${idx}`;
  remote
    .post(url)
    .addFormBody(formData)
    .onSuccess((json: any) => {
      callbacks.onSuccess(json);
    })
    .onFailed((code, msg) => {
      if (callbacks.onFailed) {
        callbacks.onFailed(code, msg);
      }
    })
    .send();
};

const DeleteAdminPostUsecase = (
  accessPoint: string,
  idx: number,
  callbacks: {
    onSuccess: (json: { status: string }) => void;
    onFailed?: (code: number, msg?: string) => void;
  }
) => {
  remote
    .delete(`/admin${accessPoint}/${idx}`)
    .onSuccess((json: any) => {
      callbacks.onSuccess(json);
    })
    .onFailed((code, msg) => {
      if (callbacks.onFailed) {
        callbacks.onFailed(code, msg);
      }
    })
    .send();
};

export {
  PostAdminPostUsecase,
  PostFormAdminPostUsecase,
  DeleteAdminPostUsecase,
  GetPostListUsecase,
  PostFormImageDetailUseCase,
  GetPostImageDetailsUseCase,
};
