import { Text, VStack } from "@chakra-ui/react";

const TestPage = () => {
  return (
    <VStack
      style={{
        height: "100vh",
        width: "100%",
        backgroundColor: "blue",
      }}
    >
      <Text fontSize="6xl" as="b" color="#364153" m={5}>
        Test
      </Text>
      <VStack
        style={{
          width: "100%",
          flexGrow: 1,
          backgroundColor: "aqua",
        }}
      >
        <VStack
          style={{
            width: "100%",
            flex: 1,
            height: "0",
            backgroundColor: "red",
            overflowY: "auto",
          }}
        >
          <div
            style={{
              width: "100%",
              backgroundColor: "yellow",
              height: "100vh",
            }}
          >
            1
          </div>

          <div
            style={{
              width: "100%",
              backgroundColor: "yellow",
              height: "70vh",
            }}
          >
            1
          </div>
        </VStack>
      </VStack>
    </VStack>
  );
};

export default TestPage;
