import { Button, Input, Text, VStack } from "@chakra-ui/react";
import HypinLogoRaw from "../../resources/hypinlogo_raw";
import LoginUsecase from "../../data/usecase/User/LoginUsecase";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const LoginPage = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    id: "",
    password: "",
  });

  const onClickLogin = () => {
    if (formData.id === "" || formData.password === "") {
      alert("아이디와 비밀번호를 입력해주세요.");
      return;
    }

    LoginUsecase(formData, {
      onSuccess: () => {
        navigate("/");
      },
    });
  };

  return (
    <VStack
      style={{
        flex: 1,

        height: "100vh",
        justifyContent: "center",
      }}
    >
      <img src={HypinLogoRaw} />
      <Input
        width="10rem"
        placeholder="Username"
        value={formData.id}
        onChange={(e) =>
          setFormData({ ...formData, id: e.target.value.trim() })
        }
      />
      <Input
        width="10rem"
        placeholder="Password"
        type="password"
        value={formData.password}
        onChange={(e) =>
          setFormData({ ...formData, password: e.target.value.trim() })
        }
      />

      <Button onClick={onClickLogin}>Login</Button>
    </VStack>
  );
};
export default LoginPage;
