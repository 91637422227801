import { remote } from "../../RemoteSource";

interface MemberUpdateInfo {
  name: string;
  desc: string;
  generation: number;
  isActing: boolean;
  linkedinUrl: string;
  picture?: File; // Optional, assuming a different method for file handling
}

const PostAdminMemberUpdateUsecase = (
  idx: number,
  body: MemberUpdateInfo,
  callbacks: {
    onSuccess: (json: { status: string }) => void;
    onFailed?: (code: number, msg?: string) => void;
  }
) => {
  const formBody = new FormData();
  formBody.append("name", body.name);
  formBody.append("desc", body.desc);
  formBody.append("generation", body.generation.toString());
  formBody.append("isActing", body.isActing.toString());
  formBody.append("linkedinUrl", body.linkedinUrl);
  if (body.picture) {
    formBody.append("picture", body.picture);
  }

  remote
    .post(`/admin/member/${idx}`)
    .addFormBody(formBody) // Sending as JSON
    .onSuccess((json: any) => {
      callbacks.onSuccess(json);
    })
    .onFailed((code, msg) => {
      if (callbacks.onFailed) {
        callbacks.onFailed(code, msg);
      }
    })
    .send();
};

export default PostAdminMemberUpdateUsecase;
