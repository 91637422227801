import { remote } from "../../RemoteSource";

const GetAdminUserList = (callback: {
  onSuccess: (json: {
    status: string;
    admins: { id: string; name: string; isSuper: boolean }[];
  }) => void;
}) => {
  remote
    .get("/admin/user/list")
    .onSuccess((json: any) => {
      callback.onSuccess(json);
    })
    .send();
};

const PostAdmenDeleteUser = (
  id: string,
  callback: {
    onSuccess: (json: { status: string }) => void;
  }
) => {
  remote
    .post("/admin/user/delete")
    .addBody({ id: id })
    .onSuccess((json: any) => {
      callback.onSuccess(json);
    })
    .send();
};

const PostAdminUserSuper = (
  admins: { id: string; isSuper: boolean }[],
  callback: {
    onSuccess: (json: { status: string }) => void;
  }
) => {
  remote
    .post("/admin/user/super")
    .addBody({ admins: admins })
    .onSuccess((json: any) => {
      callback.onSuccess(json);
    })
    .send();
};

export { GetAdminUserList, PostAdmenDeleteUser, PostAdminUserSuper };
