import { makeAutoObservable } from "mobx";
import { COOKIE_TOKEN } from "../const/remote";
import { cookieManager } from "./CookieManager";

class SessionManager {
  isLogin = false;
  isCookieAvailable = () => {
    return (
      cookieManager.readCookie(COOKIE_TOKEN) !== null &&
      cookieManager.readCookie(COOKIE_TOKEN) !== ""
    );
  };

  logout = () => {
    cookieManager.updateCookie(COOKIE_TOKEN, "", 0);
    this.isLogin = false;
  };

  setLoginStatus = () => {
    this.isLogin = this.isCookieAvailable();
  };

  constructor() {
    makeAutoObservable(this);
    this.setLoginStatus();
  }
}

export const sessionManager = new SessionManager();
