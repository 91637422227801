import {
  AlertDialog,
  Checkbox,
  HStack,
  IconButton,
  Input,
  Text,
  VStack,
} from "@chakra-ui/react";
import { observer } from "mobx-react";
import { DeleteIcon, SettingsIcon } from "@chakra-ui/icons";
import { useEffect, useState } from "react";
import ProfileUsecase from "../../data/usecase/User/ProfileUsecase";
import {
  GetAdminUserList,
  PostAdminUserSuper,
} from "../../data/usecase/User/GetAdminUserList";
import PostAdminPasswordUsecase from "../../data/usecase/User/PostAdminPassword";
import DeleteAdminUserUsecase from "../../data/usecase/User/DeleteUserUsecase";

const AdminAccountManagementPage = observer(() => {
  const [users, setUsers] = useState<
    {
      id: string;
      name: string;
      isSuper: boolean;
    }[]
  >([]);

  useEffect(() => {
    fetchAdminsList();
  }, []);

  const fetchAdminsList = () => {
    GetAdminUserList({
      onSuccess: (data) => {
        setUsers(data.admins);
      },
    });
  };

  const deleteAdminUser = (id: string) => {
    if (window.confirm(`정말 해당 어드민 계정을 삭제하겠습니까?`)) {
      DeleteAdminUserUsecase(
        {
          id: id,
        },
        {
          onSuccess: () => {
            fetchAdminsList();
          },
        }
      );
    }
  };

  const updateSuperStatus = (id: string, isSuper: boolean) => {
    const newUsers = users.map((user) => {
      if (user.id === id) {
        return {
          ...user,
          isSuper,
        };
      }
      return user;
    });
    setUsers(newUsers);
    PostAdminUserSuper(newUsers, {
      onSuccess: () => {
        fetchAdminsList();
      },
    });
  };

  return (
    <VStack
      style={{
        width: "100%",
        height: "50vh",
      }}
    >
      <Text fontSize="6xl" as="b" color="#364153" m={5}>
        관리자 계정 관리
      </Text>
      <VStack
        style={{
          height: "calc(100vh - 10rem)",
          width: "100%",
          marginBottom: "5rem",
        }}
      >
        {users.map((user, index) => {
          return (
            <HStack
              key={index}
              style={{
                backgroundColor: index % 2 === 0 ? "#f5f5f5" : "white",
                height: "3rem",
                padding: "0 0.5rem",
              }}
            >
              <Text
                style={{
                  width: "10rem",
                }}
              >
                {user.id}
              </Text>
              <Text
                style={{
                  width: "15rem",
                }}
              >
                {user.name}
              </Text>

              <Text
                style={{
                  width: "5rem",
                }}
              >
                SUPER
              </Text>
              <Checkbox
                isChecked={user.isSuper}
                onChange={(e) => {
                  updateSuperStatus(user.id, e.target.checked);
                }}
              />
              <IconButton
                aria-label="Delete User"
                icon={<DeleteIcon />}
                colorScheme="red"
                onClick={() => {
                  deleteAdminUser(user.id);
                }}
              />
            </HStack>
          );
        })}
      </VStack>
    </VStack>
  );
});

const MainPage = observer(() => {
  const [profile, setProfile] = useState({
    status: "200",
    id: "null",
    name: "null",
    isSuper: false,
  });

  const updatePassword = () => {
    const oldPassword = (
      document.getElementById("inputPassword") as HTMLInputElement
    ).value;
    const newPassword = (
      document.getElementById("inputNewPassword") as HTMLInputElement
    ).value;
    if (oldPassword === "" || newPassword === "") {
      alert("비밀번호를 입력해주세요.");
      return;
    }
    PostAdminPasswordUsecase(
      {
        password: oldPassword,
        newPassword: newPassword,
      },
      {
        onFailed(code, msg) {
          if (code === 401) {
            alert("기존 비밀번호가가 일치하지 않습니다.");
            return;
          }
          alert(code);
        },
        onSuccess() {
          alert("비밀번호가 변경되었습니다.");
        },
      }
    );
  };

  useEffect(() => {
    if (profile.id !== "null") return;
    setProfile({ ...profile, id: "loading" });
    ProfileUsecase({
      onSuccess: (data) => {
        setProfile(data);
      },
    });
  }, []);

  return (
    <VStack
      style={{
        width: "100%",
        height: "100vh",
      }}
    >
      <VStack
        style={{
          alignItems: "flex-start",
        }}
      >
        <Text fontSize="6xl" as="b" color="#364153" m={5}>
          관리자 계정 정보
        </Text>
        <HStack>
          <Text
            style={{
              width: "10rem",
            }}
          >
            ID
          </Text>
          <Input name="id" value={profile.id} isDisabled />
        </HStack>
        <HStack>
          <Text
            style={{
              width: "10rem",
            }}
          >
            NAME
          </Text>
          <Input name="name" value={profile.name} isDisabled />
        </HStack>
        <HStack>
          <Text
            style={{
              width: "10rem",
            }}
          >
            현재 비밀번호
          </Text>
          <Input name="password" type="password" id="inputPassword" />
        </HStack>
        <HStack>
          <Text
            style={{
              width: "10rem",
            }}
          >
            새 비밀번호
          </Text>
          <Input name="password" type="password" id="inputNewPassword" />

          <IconButton
            aria-label="Update Password"
            icon={<SettingsIcon />}
            onClick={() => {
              updatePassword();
            }}
          />
        </HStack>
      </VStack>
      {profile.isSuper && <AdminAccountManagementPage />}
    </VStack>
  );
});

export default MainPage;
