import { Text, VStack } from "@chakra-ui/react";
import BulletinBoard from "../components/BulletinBoard";
const AlbumPage = () => {
  return (
    <VStack
      style={{
        width: "100%",
        height: "100vh",
        alignItems: "flex-start",
      }}
    >
      <Text fontSize="6xl" as="b" color="#364153" m={5}>
        사진첩
      </Text>
      <VStack
        style={{
          height: "calc(100vh - 10rem)",
          width: "100%",
          marginBottom: "5rem",
        }}
      >
        <BulletinBoard
          getAccessPoint="/reference/picture/list"
          postAccessPoint="/reference/picture"
          getQualifer="pictureList"
          fileQualifer="picture"
          enableImageMulti
          enableImage
          imageIdxQualifier="pictureIdx"
        />
      </VStack>
    </VStack>
  );
};

export default AlbumPage;
