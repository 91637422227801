import { remote } from "../../RemoteSource";

const LoginUsecase = (
  body: {
    id: string;
    password: string;
  },
  callbacks: {
    onSuccess: (json: string) => void;
    onFailed?: (code: number, msg?: string) => void;
  }
) => {
  remote
    .post("/admin/user/login")
    .addBody(body)
    .onSuccess((json) => {
      callbacks.onSuccess(json);
    })
    .onFailed((code, msg) => {
      if (callbacks.onFailed) {
        callbacks.onFailed(code, msg);
      }
    })
    .send();
};

export default LoginUsecase;
