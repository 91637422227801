import ActingPage from "../pages/acting";
import AlumniPage from "../pages/alumni";
import CurriPage from "../pages/curri";
import GreetingPage from "../pages/greeting";
import HistoryPage from "../pages/history";
import NoticePage from "../pages/notice";
import RecruitPage from "../pages/recruit";
import JournalPage from "../pages/journal";
import AlbumPage from "../pages/album";
import ActivitiesPage from "../pages/activities";
import QnAPage from "../pages/qna";
import MainEditPage from "../pages/mainedit";
interface IAppSubStructure {
  pageName: string;
  routingPath: string;
  component?: React.ComponentType<any>;
}

interface IAppStructure {
  pageName: string;
  subPages: IAppSubStructure[];
}

const AppStructure: IAppStructure[] = [
  {
    pageName: "학회소개",
    subPages: [
      { pageName: "메인페이지", routingPath: "main", component: MainEditPage },
      { pageName: "인사말", routingPath: "greeting", component: GreetingPage },
      { pageName: "연혁", routingPath: "history", component: HistoryPage },
      { pageName: "커리큘럼", routingPath: "curri", component: CurriPage },
    ],
  },
  {
    pageName: "회원소개",
    subPages: [
      //{ pageName: "액팅 회원", routingPath: "acting", component: ActingPage },
      { pageName: "회원 목록", routingPath: "alumni", component: AlumniPage },
    ],
  },
  {
    pageName: "가입안내",
    subPages: [
      { pageName: "모집 안내", routingPath: "recruit", component: RecruitPage },
      { pageName: "Q&A", routingPath: "qna", component: QnAPage },
      { pageName: "공지사항", routingPath: "notice", component: NoticePage },
    ],
  },
  {
    pageName: "자료실",
    subPages: [
      { pageName: "학회지", routingPath: "journal", component: JournalPage },
      {
        pageName: "정기 활동",
        routingPath: "activities",
        component: ActivitiesPage,
      },
      { pageName: "사진첩", routingPath: "album", component: AlbumPage },
    ],
  },
];

export type { IAppStructure, IAppSubStructure };
export default AppStructure;
