import {
  AddIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  DeleteIcon,
  DownloadIcon,
} from "@chakra-ui/icons";
import {
  Button,
  HStack,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
} from "@chakra-ui/react";
import HtmlEditor from "./HtmlEditor";
import { PostData } from "../../data/Interface/PostData";
import { useEffect, useState } from "react";
import { GetPostImageDetailsUseCase } from "../../data/usecase/Recruit/PostsUsecase";

const BulletinBoardEditModal = ({
  isOpen,
  onClose,
  onOpen,
  currentPostData,
  enablePdf,
  fileQualifer,
  enableImage,
  postAccessPoint,
  enableImageMulti,
  imageIdxQualifier,
  uploadPost,
  enableHtml,
}: {
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
  currentPostData: PostData;
  enablePdf?: boolean;
  fileQualifer?: string;
  enableImage?: boolean;
  postAccessPoint?: string;
  enableImageMulti?: boolean;
  imageIdxQualifier?: string;
  enableHtml?: boolean;
  uploadPost: (
    postData: PostData,
    pdfFileChosen?: File,
    imageFileChosen?: File,
    imageFileChosenList?: (File | string)[]
  ) => void;
}) => {
  const [currentPostTitle, setCurrentPostTitle] = useState<string>("");
  const [currentPostContent, setCurrentPostContent] = useState<string>("");
  const [pdfFileChosen, setPdfFileChosen] = useState<File>();
  const [imageFilesChosen, setImageFilesChosen] = useState<File>();
  const [imageFilesChosenList, setImageFilesChosenList] = useState<
    (File | string)[]
  >([]);

  const updateSinglePost = () => {
    const postData = {
      ...currentPostData,
      title: currentPostTitle,
      content: currentPostContent,
      updateAt: new Date().toISOString(),
      createdAt: currentPostData.createdAt || new Date().toISOString(),
    };

    uploadPost(postData, pdfFileChosen, imageFilesChosen, imageFilesChosenList);
  };

  const savePost = () => {
    // Update the post with the new content
    // You'll need to implement the logic for updating the post in your posts state

    if (currentPostTitle === "" || currentPostContent === "") {
      alert("제목과 내용을 입력해주세요.");
      return;
    }

    updateSinglePost();
  };

  const cancelUpdate = () => {
    if (window.confirm("변경 사항을 저장하지 않고 나가시겠습니까?")) {
      clearInputFields();
      onClose();
    }
  };

  const clearInputFields = () => {
    setCurrentPostTitle("");
    setCurrentPostContent("");
    setPdfFileChosen(undefined);
    setImageFilesChosen(undefined);
    setImageFilesChosenList([]);
  };

  useEffect(() => {
    setCurrentPostTitle(currentPostData.title);
    setCurrentPostContent(currentPostData.content);

    if (currentPostData.idx && enableImageMulti) {
      GetPostImageDetailsUseCase(
        currentPostData.idx!,
        postAccessPoint!,
        imageIdxQualifier!,
        {
          onSuccess: async (data) => {
            setImageFilesChosenList(
              data.pictures.map((picture) => picture.pictureUrl)
            );
          },
        }
      );
    }
  }, [currentPostData]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="full">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Post</ModalHeader>

        <ModalBody>
          <VStack>
            <Input
              style={{
                margin: "1rem",
                padding: "1rem",
              }}
              placeholder="Title"
              value={currentPostTitle}
              onChange={(e) => setCurrentPostTitle(e.target.value)}
            />
            {enablePdf && fileQualifer && (
              <HStack
                style={{
                  width: "100%",
                }}
              >
                {(currentPostData as any)[`${fileQualifer!}Url`] && (
                  <IconButton
                    aria-label="Download"
                    colorScheme="green"
                    size="sm"
                    icon={<DownloadIcon />}
                    onClick={() =>
                      window.open(
                        (currentPostData as any)[`${fileQualifer!}Url`]
                      )
                    }
                  />
                )}
                <Input
                  type="file"
                  accept="application/pdf"
                  onChange={(e) => {
                    if (e.target.files) {
                      setPdfFileChosen(e.target.files[0]);
                    }
                  }}
                />
              </HStack>
            )}
            {enableImage && !currentPostData.idx && (
              <Input
                type="file"
                accept="image/png, image/jpeg"
                onChange={(e) => {
                  if (e.target.files) {
                    setImageFilesChosen(e.target.files[0]);
                  }
                }}
              />
            )}

            {enableImageMulti && currentPostData.idx && (
              <VStack
                style={{
                  width: "100%",
                }}
              >
                <Input
                  id="imageFileListInput"
                  hidden
                  multiple
                  type="file"
                  accept="image/*"
                  onChange={(e) => {
                    if (e.target.files) {
                      let files = [];
                      for (let i = 0; i < e.target.files.length; i++) {
                        files.push(e.target.files[i]);
                      }
                      setImageFilesChosenList([
                        ...imageFilesChosenList,
                        ...files,
                      ]);
                    }
                  }}
                />
                <HStack
                  style={{
                    width: "100%",
                    overflowX: "auto",
                    justifyContent: "flex-start",
                  }}
                >
                  {imageFilesChosenList.map((file, index) => (
                    <VStack>
                      <img
                        key={index}
                        src={
                          typeof file === "string"
                            ? file
                            : URL.createObjectURL(file)
                        }
                        style={{
                          width: "8rem",
                          height: "8rem",
                          objectFit: "cover",
                          borderRadius: "0.3rem",
                        }}
                        alt=""
                      />
                      <HStack>
                        <IconButton
                          icon={<DeleteIcon />}
                          aria-label="delete"
                          size="sm"
                          colorScheme="red"
                          onClick={() => {
                            const newList = [...imageFilesChosenList];
                            newList.splice(index, 1);
                            setImageFilesChosenList(newList);
                          }}
                        />
                        <IconButton
                          icon={<ArrowLeftIcon />}
                          aria-label="download"
                          size="sm"
                          isDisabled={index === 0}
                          colorScheme="green"
                          onClick={() => {
                            // move item to left
                            const newList = [...imageFilesChosenList];
                            const temp = newList[index];
                            newList[index] = newList[index - 1];
                            newList[index - 1] = temp;
                            setImageFilesChosenList(newList);
                          }}
                        />
                        <IconButton
                          icon={<ArrowRightIcon />}
                          aria-label="download"
                          size="sm"
                          colorScheme="green"
                          isDisabled={index === imageFilesChosenList.length - 1}
                          onClick={() => {
                            // move item to right
                            const newList = [...imageFilesChosenList];
                            const temp = newList[index];
                            newList[index] = newList[index + 1];
                            newList[index + 1] = temp;
                            setImageFilesChosenList(newList);
                          }}
                        />
                      </HStack>
                    </VStack>
                  ))}
                  <IconButton
                    icon={<AddIcon />}
                    aria-label="add"
                    size="lg"
                    colorScheme="whatsapp"
                    onClick={() => {
                      const input =
                        document.getElementById("imageFileListInput");
                      input?.click();
                    }}
                  />
                </HStack>
              </VStack>
            )}

            <div
              style={{
                width: "100%",
                height: "25rem",
              }}
            >
              {enableHtml ? (
                <HtmlEditor
                  initialHtmlContent={currentPostContent}
                  onTextChange={(content) => setCurrentPostContent(content)}
                />
              ) : (
                <Input
                  defaultValue={currentPostContent}
                  onChange={(e) => setCurrentPostContent(e.target.value)}
                />
              )}
            </div>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={() => savePost()}>
            저장
          </Button>
          <Button variant="ghost" onClick={cancelUpdate}>
            취소
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default BulletinBoardEditModal;
