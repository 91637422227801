import { MemberData } from "../../Interface/MemberData";
import { remote } from "../../RemoteSource";

const PostAdminMemberUsecase = (
  body: MemberData,
  callbacks: {
    onSuccess: (json: { status: string }) => void;
    onFailed?: (code: number, msg?: string) => void;
  }
) => {
  remote
    .post("/admin/member")
    .addBody(body)
    .onSuccess((json: any) => {
      callbacks.onSuccess(json);
    })
    .onFailed((code, msg) => {
      if (callbacks.onFailed) {
        callbacks.onFailed(code, msg);
      }
    })
    .send();
};

export default PostAdminMemberUsecase;
