import { Radio, RadioGroup, Text, VStack } from "@chakra-ui/react";
import BulletinBoard from "../components/BulletinBoard";
import { useState } from "react";
const ActivitiesPage = () => {
  const [activityType, setActivityType] = useState("mecro");

  return (
    <VStack
      style={{
        width: "100%",
        height: "100vh",
        alignItems: "flex-start",
      }}
    >
      <Text fontSize="6xl" as="b" color="#364153" m={5}>
        정기활동
      </Text>
      <RadioGroup onChange={setActivityType} value={activityType} m={5}>
        <Radio value="mecro">메크로</Radio>
        <Radio value="worldQuant">월드퀀트</Radio>
      </RadioGroup>
      <VStack
        style={{
          height: "calc(100vh - 10rem)",
          width: "100%",
          marginBottom: "5rem",
        }}
      >
        <BulletinBoard
          getAccessPoint="/reference/activity/list"
          postAccessPoint="/reference/activity"
          getQualifer="activityList"
          enableImage
          enableImageMulti
          activityType={activityType}
          imageIdxQualifier="activityIdx"
        />
      </VStack>
    </VStack>
  );
};

export default ActivitiesPage;
