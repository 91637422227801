import { Text, VStack } from "@chakra-ui/react";
import HtmlEditor from "../components/HtmlEditor";
import { useEffect, useState } from "react";
import PostAcademyCurriculumUsecase from "../../data/usecase/Academy/PostAcademyCurriculumUsecase";
import GetAcademyCurriculumUsecase from "../../data/usecase/Academy/GetAcamedyCurriculumUsecase";

const CurriPage = () => {
  const [curriculum, setCurriculum] = useState("");

  const updateHTML = (html: string) => {
    PostAcademyCurriculumUsecase(
      { curriculum: html },
      {
        onSuccess: () => {},
      }
    );
  };

  useEffect(() => {
    GetAcademyCurriculumUsecase({
      onSuccess: (data) => {
        setCurriculum(data.curriculum);
      },
    });
  }, []);

  return (
    <VStack
      style={{
        width: "100%",
        alignItems: "flex-start",
        paddingLeft: "1rem",
        height: "100vh",
      }}
    >
      <Text fontSize="6xl" as="b">
        커리큘럼
      </Text>
      <div
        style={{
          width: "95%",
          height: "70%",
        }}
      >
        <HtmlEditor initialHtmlContent={curriculum} onTextChange={updateHTML} />
      </div>
    </VStack>
  );
};

export default CurriPage;
