import { remote } from "../../RemoteSource";

const PostAdminApplicationFileUsecase = (
  body: {
    file: File;
  },
  callbacks: {
    onSuccess: (json: { status: string; applicationFileUrl: string }) => void;
    onFailed?: (code: number, msg?: string) => void;
  }
) => {
  const form = new FormData();
  form.append("applicationFile", body.file);
  console.log(form.get("applicationFile"));
  remote
    .post("/admin/recruit/applicationFile")
    .addFormBody(form)
    .onSuccess((json: any) => {
      callbacks.onSuccess(json);
    })
    .onFailed((code, msg) => {
      if (callbacks.onFailed) {
        callbacks.onFailed(code, msg);
      }
    })
    .send();
};

export default PostAdminApplicationFileUsecase;
